@import url(https://fonts.googleapis.com/css2?family=Bungee+Inline&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.banner3 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://gw.alipayobjects.com/zos/rmsportal/xTPkCNNLOnTEbGgVZOpE.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner3 .banner3-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  color: #fff;
  max-width: 845px;
  height: 500px;
  width: 80%;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}
.banner3 .banner3-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner3 .banner3-slogan {
  font-size: 68px;
  line-height: 80px;
  text-indent: 2px;
  font-weight: 600;
  margin: 26px auto 38px;
  overflow: hidden;
}
.banner3 .banner3-name-en {
  display: block;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
}
.banner3 .banner3-name {
  font-size: 24px;
  overflow: hidden;
  opacity: 0.8;
}
.banner3 .banner3-button {
  display: block;
  margin: 72px auto 0;
  background: #034365;
  background: linear-gradient(to right, #034365 0%, #001b33 100%);
  box-shadow: 0 8px 16px #0a52ab;
  border: none;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 132px;
  line-height: 42px;
  height: 42px;
  border-radius: 42px;
}
.banner3 .banner3-time {
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.banner-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.algo-img {
  width: 25vw;
  padding: 2rem;
  -webkit-filter: drop-shadow(3px 3px 1px #000);
          filter: drop-shadow(3px 3px 1px #000);
}
.algo-img:hover {
  -webkit-transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform: rotateY(180deg);
  transform-style: preserve-3d;
}
.algo-typo {
  width: 25vw;
  -webkit-filter: drop-shadow(3px 3px 1px #fff);
          filter: drop-shadow(3px 3px 1px #fff);
}
@media screen and (max-width: 767px) {
  .banner3 {
    background-attachment: inherit;
  }
  .banner3 .banner3-text-wrapper {
    width: 90%;
    height: 50%;
  }
  .banner3 .banner3-name-en {
    font-size: 12px;
  }
  .banner3 .banner3-slogan {
    font-size: 24px;
    line-height: 1.5;
    margin: 12px 0;
  }
  .banner3 .banner3-name {
    font-size: 14px;
  }
  .algo-img {
    width: 80vw;
  }
  .algo-typo {
    width: 85vw;
  }
}
.content8-wrapper.home-page-wrapper {
  overflow: visible;
  overflow: initial;
  width: calc(100% - 112px);
  margin: auto;
  border-radius: 4px;
  box-shadow: 0 0 30px #333;
  margin-bottom: 2rem;
  background: #fff;
}
.content8-wrapper.home-page-wrapper .home-page {
  margin-top: -220px;
  padding: 64px 24px;
  overflow: hidden;
}
.content8-wrapper .content-wrapper {
  margin-top: 72px;
}
.content8-wrapper .content-wrapper .content8-block-wrapper {
  margin-bottom: 60px;
}
.content8-wrapper .content-wrapper .content8-block {
  width: 100%;
  max-width: 192px;
  margin: auto;
}
.content8-wrapper .content-wrapper .content8-img {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
.content8-wrapper .content-wrapper .content8-img img {
  width: 100%;
  display: block;
}
.content8-wrapper .content-wrapper .content8-img img[src=""] {
  background: linear-gradient(to top right, #d6defc, #fff);
  padding-bottom: 100%;
}
.content8-wrapper .content-wrapper .content8-title {
  font-size: 20px;
  color: #0d1a26;
  font-weight: 400;
  margin: 24px auto 8px;
  text-align: center;
  white-space: nowrap;
}
.content8-wrapper .content-wrapper .content8-content {
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  color: #697b8c;
}
.ant-col-md-8 {
  text-align: center !important;
}
.feature-8-title {
  text-align: center;
  font-family: 'Tangerine', cursive;
}
.feature-8-title h1 {
  color: #845F1D !important;
  -webkit-filter: drop-shadow(3px 3px 5px #000);
          filter: drop-shadow(3px 3px 5px #000);
  font-size: 4rem!important;
}
.content0-feat-cont {
  width: 80%;
  text-align: justify;
  margin: auto;
  margin-bottom: 0;
}
.content-section {
  width: 60vw;
  text-align: justify;
  margin: auto;
  padding-bottom: 2rem;
}
.content0-variant-ti {
  font-family: 'Tangerine', cursive;
  font-size: 3rem!important;
  color: #845F1D !important;
}
.value-content8 {
  text-align: center;
  width: 100%;
  margin-top: 2rem;
}
.value-content8-p {
  width: 50%;
  margin: auto;
  text-align: left;
  line-height: 2rem;
}
.content-bg {
  background-color: black;
  padding: 2rem;
}
@media screen and (max-width: 767px) {
  .content8-wrapper.home-page-wrapper {
    padding-bottom: 0;
    box-shadow: none;
    width: 100%;
  }
  .content8-wrapper.home-page-wrapper .content8.home-page {
    margin: auto;
    padding-bottom: 0;
  }
  .content8-wrapper.home-page-wrapper .content-wrapper .content8-block {
    max-width: 240px;
  }
  .content-section {
    width: 80vw;
  }
  .value-content8-p {
    width: 80%;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 880px;
  }
}
.content5-wrapper {
  background-color: #fafafa;
  min-height: 720px;
}
.content5-wrapper .content5 > p {
  text-align: center;
}
.content5-wrapper .content5-img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content5-wrapper .content5-img-wrapper .block {
  margin-bottom: 24px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content {
  display: block;
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: none;
  color: rgba(0, 0, 0, 0.85);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content > span {
  width: 100%;
  height: 160px;
  display: block;
  background: #c0851f;
  padding: 5%;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content p {
  width: 100%;
  line-height: 30px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover p {
  bottom: 0;
}
.img-w-p {
  width: 60%;
}
@media screen and (max-width: 767px) {
  .content5-wrapper {
    height: auto;
    overflow: hidden;
  }
  .content5-wrapper .content5 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content5-wrapper .content5 ul li span {
    height: 168px;
  }
  .content5-wrapper .content5 ul li p {
    position: relative;
    bottom: 0;
  }
}
.footer2-wrapper {
  background-color: #070707;
  overflow: hidden;
}
.footer2-wrapper .footer2 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
.footer2-wrapper .copyright {
  float: right;
}
.footer2-wrapper .copyright > * {
  display: inline-block;
}
.footer2-wrapper .copyright-logo {
  width: 16px;
  margin-right: 8px;
}
.footer2-wrapper .copyright-logo img {
  width: 100%;
}
.footer2-wrapper .copyright-line {
  padding: 0 12px;
  margin: 0 12px;
}
.footer2-wrapper .links {
  float: left;
  display: flex;
  align-items: center;
  height: 100%;
}
.footer2-wrapper .links a {
  height: 21px;
  display: inline-block;
  margin-right: 32px;
}
.footer2-wrapper .links a img {
  display: block;
}
.ae-logo {
  text-align: center;
  padding-top: 1rem;
}
.ae-logo {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .footer2-wrapper .footer2 {
    font-size: 12px;
  }
  .footer2-wrapper .footer2.home-page {
    padding: 0;
  }
  .footer2-wrapper .footer2 > div {
    width: 90%;
    margin: auto;
  }
}
div > .templates-wrapper > .jrhtw9ph4a-editor_css {
  position: absolute;
  background-color: rgba(0, 21, 41, 0);
  box-shadow: none;
}
.home-page > .header2-menu > .active {
  color: #ffffff;
}
.home-page > .header2-menu > .menu-item:hover {
  color: #e0e7ff;
  border-bottom-color: #ffffff;
}
#Banner3_0.ksyv9sciy7g-editor_css {
  background-image: url(https://designrevision.com/images/hero/style-1/variation-1/background.jpg);
}

